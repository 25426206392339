.card-grid-1 {
    position: relative;
    border-radius: 10px;
    border: 0.88px solid var(--jobbox-color-border-2);
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;
    background: var(--jobbox-background-5);
    text-align: center;
    padding: 30px;
}

.card-shadow {
    box-shadow: var(--jobbox-box-shadow-2);
}

// new JB
.card-time {
    font-size: var(--jobbox-font-xs);
    color: var(--jobbox-color-text-mutted);
    display: inline-block;
    padding: 0px 15px 0px 15px;
    background: url(../imgs/template/icons/time.svg) no-repeat 0px 5px;
}

.card-briefcase {
    font-size: var(--jobbox-font-xs);
    color: var(--jobbox-color-text-mutted);
    display: inline-block;
    padding: 0px 15px 0px 15px;
    background: url(../imgs/template/icons/briefcase.svg) no-repeat 0px 6px;
}

.card-location {
    font-size: var(--jobbox-font-xs);
    color: var(--jobbox-color-text-mutted);
    display: inline-block;
    padding: 0px 0px 0px 17px;
    background: url(../imgs/template/icons/location.svg) no-repeat 0px 6px;
    line-height: 24px;
}

// new
.card-grid-2 {
    border-radius: 8px;
    border: 1px solid var(--jobbox-color-border-2);
    overflow: hidden;
    // margin-bottom: 24px;
    height: 100%;
    position: relative;
    background: var(--jobbox-background-5);
    i {
        font-size: var(--jobbox-font-xs);
        color: var(--jobbox-color-text-mutted);
        // margin-left: 5px;
        position: relative;
        top: 1px;
        &.fi-rr-marker {
            margin-left: 0;
        }
    }
    .btn-apply-now {
        color: var(--jobbox-color-brand-2);
    }
    &:hover {
        border-color: var(--jobbox-color-border-1);
        background-color: $background-white;
        .btn-apply-now {
            color: $color-white;
            background-color: var(--jobbox-color-brand-2);
        }
    }
    a {
        text-decoration: none;
        color: var(--jobbox-color-brand);
        &:hover {
            color: var(--jobbox-color-brand-2);
        }
    }
    .card-grid-2-image-rd {
        padding: 0px 15px 0px 0px;
        display: inline-block;
        width: 30%;
        min-width: 115px;
        max-width: 115px;
        figure {
            position: relative;
            display: inline-block;
            img {
                border-radius: 50%;
                height: 100px;
                width: 100px;
            }
        }
        &.online {
            figure {
                &::before {
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                    content: "";
                    position: absolute;
                    bottom: 8px;
                    right: 5px;
                    background: var(--jobbox-background-success);
                }
            }
        }
    }
    &.grid-bd-16 {
        background-color: $background-white;
        border-radius: 16px;
        .lbl-hot {
            position: absolute;
            top: 25px;
            left: 25px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
            height: 24px;
            background: var(--jobbox-color-brand-2);
            border-radius: 5px;
            color: $color-white;
            min-width: 42px;
            font-size: var(--jobbox-font-xs);
            line-height: 18px;

            &.full-time{
                background-color: #90da36 !important;
            }
            &.part-time{
                background-color: #f08d3c !important;
            }
            &.temporary{
                background-color: #d93674 !important;
            }
            &.freelance{
                background-color: #39c !important;
            }
            &.internship{
                background-color: #6033cc !important;
            }
        }
        .card-block-info {
            .card-text-price {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
    .card-grid-2-image {
        position: relative;
        padding: 10px;
        .tag {
            position: absolute;
            top: 15px;
            left: 15px;
            z-index: 12;
        }
        figure {
            display: block;
            img {
                width: 100%;
                border-radius: 12px;
            }
        }
    }
    .card-block-info {
        display: inline-block;
        width: 100%;
        padding: 5px 20px 20px 20px;
        .card-2-img-text {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            padding: 4px 0px 4px 40px;
            font-size: var(--jobbox-font-sm);
            max-width: 90%;
            min-height: 44px;
            .card-grid-2-img-small {
                background: #e4e4e4;
                border-radius: 50%;
                padding: 5px;
                height: 32px;
                width: 32px;
                float: left;
                margin-right: 8px;
                position: absolute;
                top: 0px;
                left: 0px;
                text-align: center;
                line-height: 30px;
            }
            span {
                display: inline-block;
                color: var(--jobbox-color-brand);
                font-size: var(--jobbox-font-sm);
            }
            img {
                max-width: 100%;
            }
        }
        .card-text-price {
            color: var(--jobbox-color-brand-2);
            font-family: var(--jobbox-font-heading);
            font-weight: 600;
            font-size: 1.125rem;
            span {
                font-size: var(--jobbox-font-sm);
                color: var(--jobbox-color-text-paragraph);
                font-weight: 400;
            }
        }
        .card-2-bottom {
            position: relative;
            .btn-grey-small {
                margin-right: 5px;
            }
            &.card-2-bottom-candidate {
                .btn-brand-hover {
                    padding: 15px 33px;
                    border: thin solid var(--jobbox-border-color-1-hover);
                    font-size: 14px;
                    &:hover {
                        color: $color-white;
                    }
                }
            }
        }
        .card-grid-2-img-medium {
            padding-left: 55px;
            font-size: var(--jobbox-font-sm);
            font-weight: 600;
            font-family: var(--jobbox-font-heading);
            color: #151515;
            line-height: 17px;
            .card-grid-2-img-small {
                background-color: var(--jobbox-background-1)3;
                height: 44px;
                width: 44px;
                border-radius: 8px;
            }
        }
        .card-profile {
            text-align: center;
            strong {
                font-size: var(--jobbox-font-lg);
                color: var(--jobbox-color-brand);
                line-height: 18px;
                font-weight: bold;
                font-family: var(--jobbox-font-heading);
                display: block;
                padding-bottom: 5px;
                &:hover {
                    color: var(--jobbox-color-brand);
                }
            }
            >span {
                color: var(--jobbox-color-text-paragraph);
            }
            .rate-reviews-small {
                span {
                    display: inline-block;
                    vertical-align: middle;
                    img {
                        height: 13px;
                        display: flex;
                    }
                }
            }
        }
    }

    .employers-info {
        border-top: 1px solid var(--jobbox-color-border-2);
        padding-top: 20px;
    }
    // new
    .flash {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 25px;
        width: 25px;
        background: url(../imgs/template/icons/flash.svg) no-repeat 0px 0px;
    }
    .card-grid-2-image-left {
        padding: 30px 20px 5px 20px;
        display: flex;
        position: relative;
        .image-box {
            min-width: 52px;
            padding-right: 15px;
        }
        .right-info {
            .name-job {
                font-size: var(--jobbox-font-lg);
                line-height: 26px;
                color: var(--jobbox-color-brand);
                font-weight: bold;
                display: block;
                &:hover {
                    color: var(--jobbox-color-brand-2);
                }
            }
            .location-small {
                background: url(../imgs/template/icons/location.svg) no-repeat 0px 5px;
                display: inline-block;
                padding: 0px 0px 0px 17px;
                font-size: var(--jobbox-font-xs);
                color: var(--jobbox-color-text-mutted);
            }
        }
    }
  
}
.jobbox-list-item {
    .card-grid-2-image-left{
        margin-bottom: 15px;
    }
}
.card-image-top {
    border: 1px solid var(--jobbox-color-border-2);
    padding: 12px 12px 20px 12px;
    border-radius: 16px;
    margin-bottom: 24px;
    .image {
        overflow: hidden;
        position: relative;
        margin-bottom: 15px;
        height: 261px;
        width: 100%;
        border-radius: 10px;
        background-color: var(--jobbox-color-border-1);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        .lbl-hot {
            position: absolute;
            top: 20px;
            left: 15px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
            height: 24px;
            background: var(--jobbox-color-border-2);
            border-radius: 5px;
            color: var(--jobbox-color-brand-2);
            min-width: 42px;
        }
        img {
            border-radius: 0px;
            width: 100%;
        }
    }
    .informations {
        position: relative;
        padding: 0 5px;
        h5 {
            margin-bottom: 5px;
        }
    }
    &:hover {
        box-shadow: var(--jobbox-box-shadow-2);
    }
}

.card-grid-3 {
    border-radius: 16px;
    border: 1px solid var(--jobbox-color-border-2);
    overflow: hidden;
    margin-bottom: 0px;
    background: $background-white;
    &:hover {
        box-shadow: var(--jobbox-box-shadow-2);
        border-color: var(--jobbox-color-border-1);
    }
    a {
        text-decoration: none;
    }
    .card-grid-3-image {
        position: relative;
        padding: 10px;
        figure {
            display: block;
            img {
                width: 100%;
                border-radius: 13px;
            }
        }
    }
    h5 {
        a {
            color: var(--jobbox-color-brand);
            &:hover {
                color: var(--jobbox-color-brand-2);
            }
        }
    }
    .card-block-info {
        display: inline-block;
        width: 100%;
        padding: 0px 18px 18px 18px;
        .card-block-rating {
            span {
                display: inline-block;
                margin: 0px 2px;
            }
        }
        .card-2-img-text {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            padding: 4px 0px 4px 40px;
            font-size: var(--jobbox-font-sm);
            .card-grid-2-img-small {
                background: #e4e4e4;
                border-radius: 50%;
                padding: 5px;
                height: 32px;
                width: 32px;
                float: left;
                margin-right: 8px;
                position: absolute;
                top: 0px;
                left: 0px;
                text-align: center;
                line-height: 30px;
            }
            span {
                display: inline-block;
                color: var(--jobbox-color-brand);
                font-size: var(--jobbox-font-sm);
            }
            img {
                max-width: 100%;
            }
        }
        .card-time {
            font-size: var(--jobbox-font-sm);
            display: inline-block;
            padding: 0px 15px 0px 25px;
            background: url(../imgs/template/icons/time.svg) no-repeat 0px 3px;
        }
        .card-text-price {
            color: var(--jobbox-color-brand-2);
            font-family: var(--jobbox-font-heading);
            font-weight: 600;
            font-size: 22px;
            span {
                font-size: var(--jobbox-font-sm);
                color: var(--jobbox-color-text-paragraph);
                font-weight: 400;
            }
        }
        .card-2-bottom {
            position: relative;
            .img-rounded {
                border-radius: 50%;
                height: 35px;
                width: 35px;
                margin: 6px 10px 0px 0px;
                vertical-align: middle;
                display: inline-block;
            }
            .info-right-img {
                line-height: 22px;
                .font-sm {
                    line-height: 18px !important;
                }
            }
        }
        .card-profile {
            strong {
                font-size: var(--jobbox-font-lg);
                color: var(--jobbox-color-brand);
                font-family: var(--jobbox-font-heading);
                font-weight: bold;
                display: block;
            }
            span {
                font-size: var(--jobbox-font-md);
                color: var(--jobbox-color-text-paragraph);
            }
        }
    }
    .card-grid-3-image-circle {
        padding-top: 35px;
        figure {
            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }
        }
    }
}

.card-grid-4 {
    background-color: $background-white;
    padding: 40px 30px 35px 30px;
    border-radius: 10px;
    border: thin solid var(--jobbox-color-border-2);
    margin-bottom: 30px;
    &:hover {
        border-color: var(--jobbox-color-border-1);
        box-shadow: var(--jobbox-box-shadow-3);
    }
    a {
        text-decoration: none;
    }
    .image-top-feature {
        figure {
            img {
                height: 126px;
                border-radius: 50%;
                width: 126px;
            }
        }
    }
    .card-grid-4-info {
        display: inline-block;
        width: 100%;
        padding: 10px 0px 0px 0px;
    }
    .btn-readmore {
        color: var(--jobbox-color-brand);
        font-size: 18px;
        font-family: var(--jobbox-font-heading);
        font-weight: 400;
        text-decoration: none;
        &:hover {
            color: var(--jobbox-color-brand-2);
        }
       
    }
}

.card-grid-5 {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 575px;
    background: url(../imgs/page/blog/img-big1.png) top left;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 30px;
    .category {
        background: url(../imgs/page/blog/picture-icon.svg) center no-repeat;
        position: absolute;
        top: 15px;
        right: 15px;
        height: 47px;
        width: 47px;
        z-index: 3;
        background-color: $background-white;
        border-radius: 50%;
        display: none;
    }
    &:hover {
        .category {
            display: inline-block;
        }
    }
    .box-cover-img {
        position: absolute;
        bottom: 0px;
        top: 10%;
        width: 100%;
        background: url(../imgs/page/blog/bg-black-trans.png) repeat-x center bottom;
        z-index: 2;
        .content-bottom {
            position: absolute;
            bottom: 20px;
            left: 20px;
        }
    }
    .img-main {
        width: 100%;
        height: auto;
    }
    .author {
        img {
            max-width: 36px;
        }
    }
}

.card-grid-6 {
    position: relative;
    .card-text-desc {
        border: 1px solid var(--jobbox-color-border-2);
        padding: 20px;
        border-radius: 4px;
        position: relative;
        margin-bottom: 35px;
        &::before {
            content: "";
            height: 30px;
            width: 33px;
            background: $background-white url(../imgs/page/about/arrow.png) no-repeat;
            bottom: -29px;
            left: 20px;
            position: absolute;
            z-index: 2;
        }
    }
    .card-image {
        display: flex;
        padding-left: 5px;
        .image {
            min-width: 75px;
            img {
                width: 64px;
                height: 64px;
                border-radius: 50%;
            }
        }
        .card-profile {
            width: 100%;
            padding-top: 5px;
        }
    }
}

.card-grid-border {
    border: 1px solid var(--jobbox-color-border-2);
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 30px;
    &:hover {
        box-shadow: var(--jobbox-box-shadow-2);
    }
    .link-arrow {
        display: inline-block;
        padding: 0px 35px 0px 0px;
        background: url(../imgs/template/icons/arrow-right.svg) no-repeat right top 2px;
        color: var(--jobbox-color-brand-2);
        font-size: var(--jobbox-font-sm);
        line-height: 22px;
    }
}

.card-list-4 {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0px 0px 15px 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid var(--jobbox-color-border-2);
    .image {
        min-width: 60px;
        padding-right: 10px;
    }
    .info-text {
        width: 100%;
        margin-top: -4px;
    }
    .card-price {
        font-size: 16px;
        line-height: 26px;
        padding-top: 0px;
        display: inline-block;
        color: var(--jobbox-color-brand-2);
        span {
            font-size: 12px;
            line-height: 12px;
            color: var(--jobbox-color-text-paragraph);
            font-weight: 500;
        }
    }
}

ul {
    li {
        &:last-child {
            .card-list-4 {
                border-bottom: 0px;
                padding-bottom: 0px;
            }
        }
    }
}
.widget{
    ul {
        li {
            &:last-child {
                padding-bottom: 0px !important;               
            }
        }
    }
    .wp-block-search__input{
        --jobbox-form--border-color: transparent;
    }
}


.box-radius-8 {
    border-radius: 8px;
    background-color: var(--jobbox-background-urgent);
    padding: 30px;
    display: flex;
    box-shadow: var(--jobbox-box-shadow-2);
    border: 1px solid var(--jobbox-color-border-2);
    .image {
        display: inline-block;
        min-width: 176px;
        padding-right: 25px;
    }
    .text-info {
        display: inline-block;
        width: 100%;
    }
}

.card-category {
    height: 255px;
    .box-cover-img {
        top: 0px;
    }
}