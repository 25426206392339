.login-register-cover{
    border-bottom: 1px solid var(--jobbox-border-color);
    position: relative;
    width: 100%;
    .img-1 {
        position: absolute;
        right: 10%;
        top: 20%;
        width: unset;        
    }
}
.divider-text-center {
    position: relative;
    text-align: center;
    &::after {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        background-color: var(--jobbox-border-color);
        top: 50%;
        left: 0;
        z-index: 1;
    }
    span {
        display: inline-block;
        background-color: #fff;
        padding: 0 15px;
        z-index: 2;
        position: relative;
    }
}
.login-register {    
    input {
        height: 53px;
        line-height: 53px;
        font-size: var(--jobbox-font-sm);
    }
}

.control-job-manager-login,
.control-job-manager-register{
    .ctrlbp-field{
        margin-bottom: 15px;
    }
    .ctrlbp-input{
        flex: none;
    }
    .ctrlbp-label.ctrlbp-col-md-3{
        width: 100%;
    }

    .ctrlbp-input-list{
        padding-left: 0;

        .ctrlbp-radio{
            margin-right: 5px;
        }
    }

    .ctrlbp-checkbox{
        margin-right: 5px;
    }

    .required{
        color: #0a2a51;
    }
}