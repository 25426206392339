.bg-white {
    background: $background-white;
}
.box-head-single {
    background-color: var(--jobbox-background-head-single);
    display: inline-block;
    width: 100%;
    padding: 55px 0px;
    position: relative;
    &.bg-2 {
        background-color: var(--jobbox-background-blue-light-2);
    }
        
    .container {
        position: relative;
        z-index: 2;
    }
    
    h3 {
        margin-bottom: 15px;
    }
    .avatar-sidebar {
        position: relative;
        text-align: center;
        display: flex;
        margin-bottom: 15px;
        justify-content: center;
        img {
            max-width: 60px;
            border-radius: 50%;
            margin-right: 10px;
        }
        .sidebar-company {
            font-weight: 600;
        }
        .sidebar-website-text {
            color: var(--jobbox-color-brand);
        }
    }
}
.img-rd-15 {
    border-radius: 15px;
    width: 100%;
}
.single-image-feature {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
}
.content-single {
    p {
        font-size: var(--jobbox-font-md);
        line-height: 200%;
        color: var(--jobbox-color-text-paragraph);
        line-height: 32px;
        margin-bottom: 20px;
    }
    h3,h4,h5 {
        margin-top: 20px;
        color: var(--jobbox-color-text-paragraph);
        font-weight: bold;
        margin-bottom: 20px;
    }
    ul {
        padding-left: 30px;
        line-height:32px;
        font-size: var(--jobbox-font-md);
        color: var(--jobbox-color-text-paragraph);
        list-style: disc;
    }
}
.author-single {
    display: inline-block;
    padding-left: 26px;
    padding-top: 10px;
    span {
        position: relative;
        background-color: $background-white;
        font-size: var(--jobbox-font-lg);
        color: var(--jobbox-color-text-paragraph);
        line-height: 28px;
        font-weight: bold;
        &::before {
            content: "";
            height: 1px;
            border-top: 1px solid var(--jobbox-border-color-5);
            width: 21px;
            position: absolute;
            top: 13px;
            left: -26px;
        }
        &::after {
            content: "";
            height: 1px;
            border-top: 1px solid var(--jobbox-border-color-5);
            width: 21px;
            position: absolute;
            top: 13px;
            right: -27px;
        }
    }
}
.single-apply-jobs {
    display: inline-block;
    width: 100%;
    padding: 30px 0px 30px 0px;
    border-top: 1px solid var(--jobbox-border-color-1);
}
.heading-border {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0px;
    &::before {
        content: "";
        position: absolute;
        top: 25px;
        left: 0px;
        width: 100%;
        height: 1px;
        z-index: 1;
        border-top: 1px solid var(--jobbox-border-color-1);
    }
    span {
        background-color: $background-white;
        display: inline-block;
        padding: 5px 10px 5px 0px;
        position: relative;
        z-index: 2;
    }
}

.sidebar-border, .sidebar-shadow {
    border: 1px solid var(--jobbox-color-border-2);
    padding: 25px;
    border-radius: 8px;
    background-color: $background-white;
    margin-bottom: 50px;
    .sidebar-heading {
        display: inline-block;
        width: 100%;
        .avatar-sidebar {
            figure {
                float: left;
                img {
                    width: 85px;
                    height: 85px;
                    border-radius: 16px;
                }
            }
            .sidebar-info {
                display: block;
                padding-left: 100px;
                position: relative;
                .sidebar-company {
                    font-size: var(--jobbox-font-lg);
                    font-family: var(--jobbox-font-heading);
                    line-height: 18px;
                    font-weight: bold;
                    display: block;
                    padding-top: 5px;
                }
                .sidebar-website-text {
                    font-size: var(--jobbox-font-xxs);
                    line-height: 18px;
                    color: var(--jobbox-color-brand);
                }
                .dropdown-toggle::after {
                    display: none;
                }
                .dropdown-menu {
                    min-width: unset;
                    box-shadow: var(--jobbox-box-shadow-2);
                    border: thin solid var(--jobbox-color-border-2);
                    a {
                        font-size: 14px;
                        color: var(--jobbox-color-black-1);
                    }
                }
            }
        }
    }
}
.sidebar-shadow
{
    box-shadow: var(--jobbox-box-shadow-2);
}
.text-description {
    font-size: var(--jobbox-font-sm);
    color: var(--jobbox-color-text-paragraph-2);
    line-height: 22px;
    display: inline-block;
    width: 50%;
    min-width: 120px;
    max-width: 120px;
}

.sidebar-list-job {
    border-top: 1px solid var(--jobbox-border-color-1);
    display: inline-block;
    width: 100%;
    padding: 25px 0px 0px 0px;
    margin: 20px 0px 0px 0px;
    ul {
        padding: 0px;
        li {
            display: inline-block;
            width: 100%;
            padding-bottom: 20px;
            .sidebar-icon-item {
                float: left;
                width: 1.6875rem;
                padding-top: 4px;
                i {
                    position: relative;
                    top: 2px;
                    font-size: 16px;
                    color: var(--jobbox-color-text-mutted);
                }
            }
            .sidebar-text-info {
                display: block;
                padding-left: 20px;
                span {
                    color: var(--jobbox-color-text-paragraph-2);
                }
                strong {
                    display: block;
                    padding-top: 0px;
                    color: var(--jobbox-color-brand);
                }
            }
        }
    }
}
.box-map
{
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
}
.ul-disc
{
    list-style-type: disc;
    padding-left: 1rem !important;
    li
    {
        list-style-type: disc;
        display: list-item !important;
        padding-bottom: 5px !important;
        color: var(--jobbox-color-text-paragraph);
        font-size: var(--jobbox-font-sm);
        line-height: 22px;
    }
}
.sidebar-normal {
    padding: 29px 33px;
}
.ul-lists {
    padding: 15px 0px 15px 15px;
    li {
        list-style-type: disc;
        color: var(--jobbox-color-text-paragraph);
        padding-bottom: 5px;
        a {
            color: var(--jobbox-color-text-paragraph);
            text-decoration: none;
            &:hover {
                color: var(--jobbox-color-brand);
            }
        }
    }
}
.btn-dots-abs-right {
    position: absolute;
    top: 0px;
    right: 0px;
}

.job-overview {
    border: thin solid var(--jobbox-color-border-2);
    // box-shadow: var(--jobbox-box-shadow-2);
    border-radius: 8px;
    padding: 20px 30px 30px 30px;
    margin-bottom: 50px;

    .job-employment-info{
        display: flex;
        flex-wrap: wrap;
        row-gap: 25px;
        padding-left: 0;
        li{
            list-style: none;
            display: flex;
            width: 50%;
        }
    }

    .sidebar-icon-item {
        font-size: 18px;
        color: #a0abb8;
        min-width: 20px;
        position: relative;
        bottom: 5px;
    }
    .sidebar-text-info
    {
        display: flex;
        .small-heading
        {
            display: inline-block;
            font-size: var(--jobbox-font-md);
            line-height: 24px;
            font-weight: 500;
        }
    }
    .text-description {
        font-size: 16px;
        color: var(--jobbox-color-text-paragraph-2);
        line-height: 24px;
        font-weight: 400;
    }
}

.job-meta {
    font-size: var(--jobbox-font-xxs);
    color: var(--jobbox-color-text-paragraph);
    .company {
        font-weight: bold;
        color: var(--jobbox-color-brand);
    }
    span {
        margin-right: 15px;
        display: inline-block;
    }
}
.d-middle
{
    vertical-align: middle;
}
.d-baseline
{
    vertical-align: baseline;
}
.link-underline
{
    font-size: 12px;
    line-height: 18px;
    color: var(--jobbox-color-brand);
    font-weight: 400;
    text-decoration: underline;
    display: block;
}

//social share links
.social-share-box{
    a {
        width: 36px;
        height: 36px;
        border-radius: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &.facebook{
            background-color: #e7f1fe;
            color: #1877f2;
        }

        &.twitter{
            background-color: #e8f5fe;
            color: #1da1f2;
        }

        &.reddit{
            background-color: #ffece5;
            color: #ff4500;
        }

        &.linkedin{
            background-color: #e7f1fe;
            color: #1877f2;
        }

        &:hover{
            background-color: #1877f2;
            color: #fff;
        }
    }

    i{
        font-size: 1rem;

        &:hover{
            color: #fff;
        }
    }
}

.wp-job-manager-bookmarks-form{
    &.has-bookmark{
        .remove-bookmark-wrapper{
            display: inline-block;
        }
    }

    a.bookmark-notice{
        &::before{
            margin: 2px 10px 0 0; 
        }
    }
}