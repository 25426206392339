/*TYPOGRAPHY*/
body {
    color: var(--jobbox-color-brand);
    font-family: var(--jobbox-font-text);
    font-size: 14px;
    line-height: 1.7142857;
    font-style: normal;
    font-weight: 400;
    background-color: var(--jobbox-background-white);
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading,
.display-1,
.display-2,
.heading-lg,
.heading-sm-1 {
    color: var(--jobbox-color-brand);
}