.btn,
.login-submit .button {
    font-family: var(--jobbox-font-text);
    border-radius: 8px;
    padding: 13px 25px;
    font-size: var(--jobbox-font-sm);
    border-color: transparent;
    line-height: 1;
    transition: 0.2s;

    &.button-primary,
    &.btn-default {
        color: $color-white;
        background-color: var(--jobbox-color-brand-2);
        line-height: 26px;
        padding: 10px 25px;

        &:hover {
            background-color: var(--jobbox-color-brand);
            transform: translateY(-2px);
            transition: 0.2s;
        }

        &:focus {
            color: #fff;
        }
    }
}

.btn-grey {
    padding: 17px 20px;
    gap: 10px;
    background: var(--jobbox-color-border-2);
    border-radius: 8px;
    color: var(--jobbox-color-brand-2);
    font-size: var(--jobbox-font-sm);
    line-height: 14px;

    &:hover {
        background: var(--jobbox-color-border-2);
    }
}

.btn-grey-small {
    background-color: var(--jobbox-background-6);
    font-size: var(--jobbox-font-xs);
    padding: 7px 10px;
    border-radius: 5px;
    color: var(--jobbox-color-text-paragraph) !important;

    &:hover {
        color: var(--jobbox-color-brand-2) !important;
        background-color: var(--jobbox-background-6);
    }
}

.btn-grey-big {
    padding: .75rem 1.375rem;
    gap: 10px;
    background: var(--jobbox-color-border-2);
    border-radius: 4px;
    color: var(--jobbox-color-brand);
    font-size: var(--jobbox-font-sm);
    line-height: 22px;
    font-weight: 600;

    &:hover {
        color: var(--jobbox-color-brand-2);
        background: var(--jobbox-color-border-2);
    }
}

.btn-apply-now {
    background-color: var(--jobbox-color-border-2);
    color: var(--jobbox-color-brand-2);
    padding: 12px 10px;
    min-width: 95px;
    border-radius: 4px;
    font-size: var(--jobbox-font-xs);
    text-transform: capitalize;
    font-weight: var(--jobbox-btn-font-weight, 600);
}

.btn-apply {
    background-color: var(--jobbox-color-brand-2);
    color: $color-white;
    padding: 12px 20px;
    border-radius: 4px;

    &:focus,
    &:hover {
        background-color: #05264E;
        color: #ffffff;
    }
}

.btn-apply-icon {
    background-image: url(../imgs/template/icons/apply.svg);
    background-position: 15px 11px;
    background-repeat: no-repeat;
    padding-left: 40px;
}

.btn-apply-big {
    background-color: var(--jobbox-color-brand-2);
    color: $color-white;
    padding: 18px 35px;
    border-radius: 4px;

    &.btn-apply-icon {
        padding-left: 50px;
        background-position: 21px 17px;
    }

    &:hover {
        color: #fff;
        background-color: var(--jobbox-color-brand);
    }
}

.btn-apply-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 16px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-call-icon {
    background-image: url(../imgs/template/icons/call.svg);
    background-position: 24px 17px;
    background-repeat: no-repeat;
    padding: 18px 30px 18px 65px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.btn-download-icon {
    background-image: url(../imgs/template/icons/icon-download.svg);
    background-position: 24px 17px;
    background-repeat: no-repeat;
    padding: 18px 30px 18px 65px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.btn-tag {
    padding: 1px 10px;
    background: var(--jobbox-color-border-2);
    border-radius: 5px;
    color: var(--jobbox-color-brand-2);
    min-width: 42px;
    font-size: var(--jobbox-font-xs);
    line-height: 18px;
    font-weight: 600;

    &:hover {
        background: var(--jobbox-color-border-2);
        color: var(--jobbox-color-black-1);
    }
}

.btn-tags-sm {
    padding: 6px 18px;
    ;
    color: var(--jobbox-color-text-paragraph-2);
    border-radius: 5px;
    color: var(--jobbox-color-brand-2);
    min-width: 42px;
    font-size: var(--jobbox-font-xs);
    line-height: 18px;
    background-color: var(--jobbox-background-8);
}

.tag-cloud-link,
.btn-border-3 {
    padding: 12px 20px;
    background: var(--jobbox-color-border-3);
    border-radius: 8px;
    color: var(--jobbox-color-brand);
    font-size: var(--jobbox-font-sm) !important;
    line-height: 22px;
    margin-bottom: 10px;

    &:hover {
        background: var(--jobbox-color-border-3);
    }
}

.btn-border-brand-2 {
    padding: 12px 20px;
    border: 1px solid var(--jobbox-color-brand-2);
    background-color: #ffffff;
    border-radius: 8px;
    color: var(--jobbox-color-brand-2);
    font-size: 14px;
    line-height: 22px;

    &:hover {
        border: 1px solid var(--jobbox-color-brand-2);
        color: #000;
    }
}

.btn-brand-1 {
    padding: 18px 25px;
    background: var(--jobbox-color-brand);
    color: $color-white;
    border-radius: 4px;

    &:hover,
    &:active {
        background-color: var(--jobbox-color-brand-2);
        color: $color-white;
    }
}

.btn-icon-load {
    background-image: url(../imgs/template/icons/icon-load.svg);
    background-position: 18px center;
    background-repeat: no-repeat;
    padding-left: 42px;
}

.btn-paragraph-2 {
    padding: 8px 16px;
    background: var(--jobbox-color-text-paragraph-2);
    border-radius: 5px;
    color: var(--jobbox-color-brand-2);
    min-width: 42px;
    font-size: 11px;
    color: $color-white;
    line-height: 14px;
}

.btn-send-message {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $color-white;
    padding: 15px 28px 15px 60px;
    background: var(--jobbox-color-brand-2) url(../imgs/page/company/icon-email.svg) no-repeat 25px 15px;
    border-radius: 4px;

    &:hover {
        background-color: var(--jobbox-color-brand);
        color: $color-white;
    }
}

.btn-border {
    border: 1px solid var(--jobbox-color-border-1);
    padding: 10px 23px 10px 23px;
    border-radius: 10px;
    background: $background-white;
    color: var(--jobbox-color-text-paragraph);
    font-size: var(--jobbox-font-md);
    line-height: 26px;
    transition-duration: 0.2s;
    font-weight: bold;

    &:hover {
        color: $color-white;
        background-color: var(--jobbox-color-brand);
        transition-duration: 0.2s;
        transform: translateY(-3px);
    }
}

.btn-sm {
    padding: 12px 20px;
    vertical-align: middle;

    img {
        float: left;
        margin: 0px 10px 0px 0px;
    }
}

.btn-brand-hover {
    padding: 14px 25px 14px 25px;
}

.btn-brand-hover:hover {
    background-color: var(--jobbox-background-brand);
    color: $color-white;
}

.box-button-shadow {
    position: relative;
    display: inline-block;

    &::before {
        background: #9777fa;
        filter: blur(20px);
        border-radius: 10px;
        content: "";
        position: absolute;
        width: 144px;
        height: 39px;
        z-index: 1;
        left: 0px;
        right: 0px;
        top: 10px;
        margin: auto;
    }

    a {
        position: relative;
        z-index: 12;
    }
}

.btn-link {
    font-family: var(--jobbox-font-heading);
    font-style: normal;
    font-weight: normal;
    font-size: var(--jobbox-font-sm);
    line-height: 18px;
    color: var(--jobbox-color-brand);
    text-decoration: underline;

    &:hover {
        color: var(--jobbox-color-brand);
    }
}

.btn-arrow-right {
    background: url(../imgs/page/homepage2/arrow-right.svg) no-repeat right 15px top 3px;
    color: var(--jobbox-color-brand-2);
    padding: 0px 40px 0px 0px;
    font-size: var(--jobbox-font-xs);
    line-height: 12px;
    font-weight: bold;
}

/*button switch*/
.switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
    vertical-align: middle;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--jobbox-background-grey);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 5px;
    bottom: 5px;
    background-color: var(--jobbox-background-brand);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: var(--jobbox-background-primary-trans);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--jobbox-color-brand);
}

input:checked+.slider:before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/*End button Switch On Off*/
span.btn {
    cursor: unset;
}

.social-login {
    height: 53px;
    border-radius: 4px;
    border: 1px solid var(--jobbox-border-color);
    width: 100%;
    line-height: 53px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    img {
        margin-right: 4px;
    }

    &:hover {
        color: var(--jobbox-color-brand-2);
        box-shadow: var(--jobbox-box-shadow-2);
    }
}

.btn-brand-1 {
    .elementor-button-text {
        &:hover {
            color: #fff;
        }
    }
}

.btn-icon-load-wrap {
    position: relative;
    text-align: center;
    z-index: 9;
}

.btn-icon-more {
    background-image: url(../imgs/template/icons/icon-more.svg);
    background-position: right 16px center;
    background-repeat: no-repeat;
    padding-right: 50px;
}

.wp-job-manager-bookmarks-form a.bookmark-notice {
    padding: 10px 23px 10px 23px;
}