.hero-3{
    .swiper-pagination-style-2
    {
        .swiper-pagination-customs {
            background-color: var(--jobbox-color-border-1);
        }
        .swiper-pagination-customs-active, .swiper-pagination-customs:hover
        {
            background-color: $background-white;
        }
    }

    .swiper-button-next,
    .swiper-button-prev{
        display: none;
    }
}

.hero-5{
    .elementor-widget-image-carousel{
        .swiper-slide {
            text-align: left;
        }
    }
}

.img-rounded
{
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0px 10px 0px 0px;
    vertical-align: middle;
    display: inline-block;
}
.lh-14 {
    line-height: 14px !important;
}
.text-lbl {
    color: var(--jobbox-background-green);
    font-size: 12px;
    line-height: 18px;
    display: block;
}
.left-trust {
    position: relative;
}
.right-logos {
    .swiper-slide {
        text-align: center;
        line-height: 40px;
        a img {
            opacity: 1;
            max-height: 20px;
            width: 100%;
        }
    }
}
.box-trust {
    border: 1px solid var(--jobbox-color-border-2);
    border-radius: 4px;
    padding: 30px 30px 25px 30px;
    position: relative;
}
.box-checkbox {
    padding: 0px 0px 0px 30px;
    background: url(../imgs/page/homepage3/check-box.svg) no-repeat 0px 4px;
}
.img-job-search {
    max-width: 90%;
}