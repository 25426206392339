/*RESET*/

main {
	display: block;
	clear: both;
}
thead {
    font-weight: 600;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}

blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
img {
    max-width: 100%;
}
input,
select,
button,
textarea {
    font-family: var(--jobbox-font-text);
    font-size: var(--jobbox-font-sm);
}
*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}
input:focus::-moz-placeholder {
    opacity: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}
a {
    color: var(--jobbox-color-brand);
    &:hover {
        color: var(--jobbox-color-brand-2);
    }
    *
    {
        &:hover
        {
            color: var(--jobbox-color-brand-2);
        }
    }
}
li.hr {
    span {
        width: 100%;
        height: 1px;
        background-color: #e4e4e4;
        margin: 20px 0;
        display: block;
    }
}
p {
    color: var(--jobbox-color-text-paragraph);
}/*--- Common Classes---------------------*/
::selection {
    background: var(--jobbox-color-brand); /* WebKit/Blink Browsers */
    color: #fff;
}
::-moz-selection {
    background: var(--jobbox-color-brand); /* Gecko Browsers */
    color: #fff;
}
::placeholder {
    color: var(--jobbox-color-text-mutted);
    opacity: 1;
}
:-ms-input-placeholder,
::-webkit-input-placeholder {
    color: var(--jobbox-color-text-mutted);
    opacity: 1;
}
.fix {
    overflow: hidden;
}
.hidden {
    display: none;
}
.clear {
    clear: both;
}
.section {
    float: left;
    width: 100%;
}
.f-right {
    float: right;
}
.capitalize {
    text-transform: capitalize;
}
.uppercase {
    text-transform: uppercase;
}
.bg-img {
    background-position: center center;
    background-size: cover;
}
.position-relative {
    position: relative;
}
.height-100vh {
    height: 100vh !important;
}
*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
    outline: none !important;
    box-shadow: none;
}
.border-radius {
    border-radius: 4px;
}
.border-radius-5 {
    border-radius: 5px;
}
.border-radius-10 {
    border-radius: 10px;
}
.border-radius-15 {
    border-radius: 15px;
}
.border-radius-20 {
    border-radius: 20px;
}
.hover-up {
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    &:hover {
        transform: translateY(-3px);
        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    }
}

.none-shadow {
    box-shadow: none !important;
}
.form-control {
    border: 1px solid var(--jobbox-color-border-2);
    border-radius: 4px;
    background-color: $background-white;
    padding: 11px 15px 13px 15px;
    width: 100%;
    color: var(--jobbox-color-text-mutted);
    &::placeholder {
        color: var(--jobbox-color-text-mutted);
    }
}
.form-group {
    position: relative;
    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        font-size: 18px;
        color: var(--jobbox-color-text-mutted);
    }
}
.form-icons {
    padding-left: 41px;
}
.text-white {
    color: $color-white;
}
@media (min-width: 1400px) {
    .container {
        max-width: 1350px;
    }
}
strong {
    font-weight: bold;
}
a {
    text-decoration: none;
}
.section-box {
    display: inline-block;
    width: 100%;
    overflow: visible;
}
.text-shadow {
    text-shadow: var(--jobbox-text-shadow);
}
.img-responsive {
    max-width: 100%;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: var(--jobbox-color-text-paragraph);
    line-height: 1.857;
    font-family: var(--jobbox-font-heading);
    font-size: var(--jobbox-font-sm);
}
.select2-search.select2-search--dropdown {
    padding: 0;
    margin-bottom: 10px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: thin solid var(--jobbox-border-color);
    border-radius: 4px;
    height: 40px;
    padding-left: 15px;
}
.select2-dropdown {
    border-color: $color-white;
    box-shadow: var(--jobbox-box-shadow-2);
}
.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
    border: thin solid #ececec;
    padding: 15px;
    margin-top: 25px;
    border-radius: 4px;
    min-width: 200px;
    z-index: 30;
}
.select-style {
    .select2-selection--single .select2-selection__rendered {
        color: var(--jobbox-color-text-mutted);
    }
    .select2 {
        border: 1px solid var(--jobbox-color-border-2);
        border-radius: 4px;
        padding: 10px 20px 10px 20px;
        display: inline-block;
        width: 100% !important;
    }
    .select2 .select2-selection__arrow {
        background: url(../imgs/template/icons/arrow-down.svg) no-repeat right 10px center;
        top: 11px;
        width: 26px;
        opacity: 0.5;
    }
    .select2 .select2-selection__arrow b {
        display: none !important;
    }
}
.select-style-icon {
    .select2 {
        padding-left: 33px;
    }
}
.dropdown {
    position: relative;
    .dropdown-menu.show {
        border: thin solid var(--jobbox-color-border-1);
        box-shadow: var(--jobbox-box-shadow-2);
        background-color: $color-white;
        border-radius: 10px;
        padding: 0;
        overflow: hidden;
        .dropdown-item {
            padding: 10px 20px;
            color: var(--jobbox-color-text-paragraph);
            font-size: 14px;
            &.active {
                text-decoration: none;
                background-color: var(--jobbox-color-border-3);
            }
            &:active {
                text-decoration: none;
                background-color: var(--jobbox-color-border-3);
            }
        }
    }
    &.dropdown-sort {
        .dropdown-menu.show {            
            border-radius: 10px;
            margin-top: 20px;
        }
    }
}
h1
{
    font-family: var(--jobbox-font-heading);
    font-style: normal;
    font-weight: 800;
    font-size: var(--jobbox-font-3xl);
    line-height: 1.268;
    color: var(--jobbox-color-brand);
}
h2
{
    font-family: var(--jobbox-font-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.25;
    color: var(--jobbox-color-brand);
}
h3
{
    font-family: var(--jobbox-font-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.25;
    color: var(--jobbox-color-brand);
}
h4
{
    font-family: var(--jobbox-font-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.25;
    color: var(--jobbox-color-brand);
}
h5
{
    font-family: var(--jobbox-font-heading);
    font-style: normal;
    font-weight: 700;
    font-size: var(--jobbox-font-xl);
    line-height: 1.3;
    color: var(--jobbox-color-brand);
}
h6
{
    font-family: var(--jobbox-font-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.625;
    color: var(--jobbox-color-brand);
}
.font-lg, .font-md, .font-sm, .font-xs, .font-xxs
{
    font-family: var(--jobbox-font-text);
    font-style: normal;
    font-weight: 400;
}
.font-lg
{
    font-weight: 500;
    font-size: var(--jobbox-font-lg);
    line-height: 1.445;
}
.font-md
{
    font-size: var(--jobbox-font-md) !important;
    line-height: 1.5 !important;
}
.font-sm
{
    font-weight: 500;
    font-size: var(--jobbox-font-sm) !important;
    line-height: 1.571 !important;
}
.font-xs
{
    font-weight: 500 !important;
    font-size: var(--jobbox-font-xs) !important;
    line-height: 18px !important;
}
.font-xxs
{
    font-size: var(--jobbox-font-xxs) !important;
    line-height: 1.6;
}
.font-bold
{
    font-weight: bold;
}
.font-regular
{
    font-weight: 400;
}
.color-text-paragraph
{
    color: var(--jobbox-color-text-paragraph);
}
.color-text-paragraph-2
{
    color: var(--jobbox-color-text-paragraph-2);
}
.color-text-mutted
{
    color: var(--jobbox-color-text-mutted);
}
.color-white
{
    color: $color-white !important;
}
.text-14
{
    font-size: var(--jobbox-font-sm);
    line-height: 1;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
}
.text-32
{
    font-size: 32px;
    line-height: 1.25;
    font-weight: 800;
}
.text-52
{
    font-size: 52px;
    line-height: 1.26923;
    font-weight: 800;
    color: var(--jobbox-color-brand)
}
.text-42
{
    font-size: 42px;
    line-height: 1.2619;
    font-weight: 700;
    color: var(--jobbox-color-brand)
}
.color-green
{
    color: var(--jobbox-color-green);
}
.color-brand-1
{
    color: var(--jobbox-color-brand);
}
.color-brand-2
{
    color: var(--jobbox-color-brand-2)
}
.op-70
{
    opacity: 0.7;
}
.f-18
{
    font-size: 18px;
}
.box-related-job
{
    display: inline-block;
    width: 100%;
    padding: 20px 0px;
    border-top: 1px solid var(--jobbox-color-border-2);
    margin-top: 30px;
}
.social-share-link
{
    display: inline-block;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 2px;
}
.share-facebook
{
    background-image: url(../imgs/page/about/facebook.svg);
   
}
.share-twitter
{
    background-image: url(../imgs/page/about/twitter.svg);
   
}
.share-instagram
{
    background-image: url(../imgs/page/about/instagram.svg);
  
}
.share-linkedin
{
    background-image: url(../imgs/page/about/linkedin.svg);
   
}
.box-progress-bar
{
    .progress
    {
        height: 6px;
        position: relative;
        overflow: visible;
        border-radius: 5px;
        background-color: var(--jobbox-color-border-2);
        .bg-paragraph-2 {
            overflow: visible;
            border-radius: 10px;
            position: relative;
            background-color: var(--jobbox-color-text-paragraph-2) !important;
            span
            {
                position: absolute;
                top: -25px;
                right: -14px;
                color: var(--jobbox-color-text-paragraph-2);
                font-size: var(--jobbox-font-xs);
                line-height: 1.5;
            }
            &::before
            {
                content: "";
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background-color: var(--jobbox-color-text-paragraph-2);
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }
        .bg-brand-2 {
            overflow: visible;
            border-radius: 10px;
            position: relative;
            background-color: var(--jobbox-color-brand-2) !important;
            span
            {
                position: absolute;
                top: -25px;
                right: -14px;
                color: var(--jobbox-color-text-paragraph-2);
                font-size: var(--jobbox-font-xs);
                line-height: 1.5;
            }
            &::before
            {
                content: "";
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background-color: var(--jobbox-color-brand-2);
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }
    }
}

iframe {
    width: 100%;
}
.bg-green {
    background-color: var(--jobbox-background-green) !important;
}
.bg-orange {
    background-color: var(--jobbox-background-orange) !important;
}
.bg-pink {
    background-color: var(--jobbox-background-pink) !important;
}
.bg-9 {
    background-color: var(--jobbox-background-9) !important;
}
.bg-14 {
    background-color: var(--jobbox-background-1)4 !important;
}
.bg-15 {
    background-color: var(--jobbox-background-1)5 !important;
}

.ctrlbp-field{
    &.required{
        color: inherit;
    }
}