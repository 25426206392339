.cb-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    line-height: 21px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cb-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $background-white;
    border: 1px solid var(--jobbox-color-border-2);
    border-radius: 7px;
}

.cb-container input:checked~.checkmark {
    border: 2px solid var(--jobbox-color-brand-2);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.cb-container input:checked~.checkmark:after {
    display: block;
}

.cb-container .checkmark:after {
    left: 0px;
    top: -1px;
    width: 21px;
    height: 22px;
    border-radius: 5px;
    background: var(--jobbox-color-brand-2) url(../imgs/template/icons/tick.svg) no-repeat center;
}


/*COMPONENTS -> FORM*/

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="date"],
input[type="time"],
input[type="tel"] {
    border: 1px solid var(--jobbox-color-border-2);
    border-radius: 4px;
    height: 50px;
    box-shadow: none;
    padding-left: 20px;
    font-size: var(--jobbox-font-sm);
    width: 100%;
    &:focus {
        background: transparent;
        border: 1px solid var(--jobbox-color-border-3);
    }
}

select {
    width: 100%;
    background: transparent;
    border: 0px solid var(--jobbox-color-border-1);
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: var(--jobbox-font-md);
    color: var(--jobbox-color-text-paragraph);
}

option {
    background: #fff;
    border: 0px solid #626262;
    padding-left: 10px;
    font-size: var(--jobbox-font-md);
}

textarea {
    border: 1px solid var(--jobbox-border-color);
    border-radius: 10px;
    height: 50px;
    box-shadow: none;
    padding: 10px 10px 10px 20px;
    font-size: var(--jobbox-font-md);
    width: 100%;
    min-height: 250px;
    &:focus {
        background: transparent;
        border: 1px solid var(--jobbox-border-color-1-hover);
    }
}


/*contact form*/

.contact-from-area {
    .contact-form-style {
        button {
            font-size: var(--jobbox-font-lg);
            font-weight: 500;
            padding: 20px 40px;
            color: #ffffff;
            border: none;
            background-color: var(--jobbox-color-brand);
            border-radius: 10px;
            font-family: var(--jobbox-font-heading);
            &:hover {
                background-color: var(--jobbox-color-brand-2) !important;
            }
        }
    }
}

.form-group {
    margin-bottom: 1rem;
    input {
        background: #fff;
        border: 1px solid var(--jobbox-border-color);
        height: 53px;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding-left: 20px;
        font-size: var(--jobbox-font-md);
        width: 100%;
        &:focus {
            background: transparent;
            border-color: var(--jobbox-border-color-1-hover);
        }
        &.form-icons {
            padding-left: 42px;
        }
    }
}

label {
    margin-bottom: 5px;
}

.mt-6 {
    margin-top: 6px !important;
}
.modal-lg, .modal-xl {
	max-width: 600px;
}
.modal-content {
    .btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 2;
    }
}
.apply-job-form {
    background-image: url(../imgs/template/bg-1.svg);
    background-repeat: no-repeat;
    .text-brand-2 {
        background: #d5deff;
        display: inline-block;
        border-radius: 8px;
        padding: 5px 15px;
    }
}

.wp-block-categories-dropdown.wp-block-categories,
.wp-block-archives-dropdown.wp-block-archives {
    select {
        border: 1px solid #ccc;
        padding: .625rem;
        border-radius: 5px;
    }
}

.ctrljm-frontend-form .ctrlbp-text-wrapper:has(input#post_title) {
    display: flex !important;
}
 