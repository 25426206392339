.job-list-list {
    .list-recent-jobs {
        margin-top: 0px;
    }
}
.job-tags {
    a {
        background-color: var(--jobbox-background-7);
        color: var(--jobbox-color-text-paragraph);
        border-radius: 15px;
        padding: 8px 20px;
        display: inline-block;
        & + a {
            margin-left: 5px;
        }
    }
}
.display-list
{
    .flash
    {
        top: 32px;
    }
}
.content-page 
{
    .display-list
    {
        .card-grid-2
        {
            .card-block-info
            {
                padding-top: 0px;
                .card-text-price
                {
                    font-size: var(--jobbox-font-md);
                    line-height: 26px;
                    padding-top: 8px;
                    display: inline-block;
                    span
                    {
                        font-size: var(--jobbox-font-xs);
                        line-height: 12px;
                    }
                }
            }
        } 
    }
}

.jobbox-list-item{
    .recruiter-data-no{
        .job-skills{
            position: absolute;
            right: 0;
        }
        .card-block-info{
            padding-top: 30px;
        }
    }
}