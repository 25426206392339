.header {
    padding: 30px 0;
    width: 100%;
    .main-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .header-nav
        {
            text-align: center;
        }
        .header-left {
            display: flex;
            align-items: center;
            .header-logo {
                margin-right: 0;
                min-width: 139px;

                img{
                    width: var(--jobbox-logo-size, auto);
                }
            }
        }
    }
    .nav-main-menu {
        display: flex;
        width: auto;
        padding: 0px;
        text-align: left;
    }
    .main-menu {
        li {
            float: left;
            padding: 10px 22px;
            position: relative;
            &:hover, &.active {
                > a{
                    color: var(--jobbox-color-brand-2);
                    &::before
                    {
                        content: "";
                        height: 2px;
                        width: 100%;
                        background-color: var(--jobbox-color-brand-2);
                        position: absolute;
                        bottom: -10px;
                        left: 0px;
                    }
                }
            }
            &.has-children:last-child,
            &.has-children {
                padding: 10px 30px 10px 22px;
                > a {
                    &::after {
                        content: "\f151";
                        font-family: uicons-regular-rounded !important;
                        font-style: normal;
                        font-weight: normal !important;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1 !important;
                        font-size: 18px;
                        opacity: 1;
                        margin-left: 4px;
                        position: absolute;
                        top: -1px;
                        right: -19px;
                        color: var(--jobbox-dropdown-icon-color, #A0ABB8);
                    }
                }
                
            }
            &.hr {
                padding: 0px 22px;
                span {
                    background-color: var(--jobbox-border-color);
                    height: 1px;
                    width: 100%;
                    display: block;
                    margin: 5px 0;
                }
            }
            a {
                font-family: var(--jobbox-font-heading);
                font-style: normal;
                font-weight: 500;
                font-size: var(--jobbox-font-sm);
                line-height: 1;
                color: var(--jobbox-color-brand);
                display: block;
                padding: 0px;
                text-decoration: none;
                position: relative;
                &.active {
                    color: var(--jobbox-color-brand-2);                    
                    &::before
                    {
                        content: "";
                        height: 2px;
                        width: 100%;
                        background-color: var(--jobbox-color-brand-2);
                        position: absolute;
                        bottom: -10px;
                        left: 0px;
                    }
                }
                i {
                    font-size: 10px;
                    opacity: 0.5;
                    margin-left: 3px;
                }
            }
            ul {
                opacity: 0;
                visibility: hidden;
                transition-duration: 0.2s;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 999;
                min-width: 220px;
                border-radius: 10px;
                border: 1px solid #ececec;
                -webkit-box-shadow: var(--jobbox-box-shadow-2);
                box-shadow: var(--jobbox-box-shadow-2);
                background-color: $background-white;
                list-style: none;
                padding: 10px 0px 10px 0;
                li {
                    width: 100%;
                    padding: 0px;
                    a {
                        padding-top: 8px;
                        padding-bottom: 8px;
                        font-size: 14px;
                        color: var(--jobbox-color-black-1);
                        position: relative;
                        padding: 10px 22px 10px 22px;
                        transition: 0.3s;
                        // &::after {
                        //     content: "";
                        //     position: absolute;
                        //     left: 22px;
                        //     top: 18px;
                        //     width: 5px;
                        //     height: 5px;
                        //     background-color: var(--jobbox-color-brand);
                        //     border-radius: 50%;
                        //     opacity: 0.7;
                        //     transition-duration: 0.2s;
                        // }
                        &:hover, &.active {
                            // padding-left: 25px;
                            transition: 0.5s;                            
                            &::after {
                                opacity: 1;
                                transition-duration: 0.3s;
                                background-color: var(--jobbox-color-brand-2);
                            }
                            &::before
                            {
                                display: none;
                            }
                        }
                    }
                    ul {
                        top: 0px;
                        left: 100%;
                    }
                }
            }
            &:hover > ul {
                opacity: 1;
                visibility: visible;
                margin-top: 0px;
                li {
                    width: 100%;
                }
            }
        }
    }
    .text-link-bd-btom {
        font-family: var(--jobbox-font-heading);
        font-style: normal;
        font-weight: 500;
        font-size: var(--jobbox-font-sm);
        line-height: 150%;
        color: var(--jobbox-color-brand);
        display: inline-block;
        position: relative;
        text-decoration-line: underline;
        &:hover {
            color: var(--jobbox-color-brand-2);
        }
        // &::before {
        //     content: "";
        //     background: var(--jobbox-color-brand);
        //     width: 6px;
        //     height: 6px;
        //     border-radius: 50%;
        //     position: absolute;
        //     left: 0;
        //     top: 50%;
        //     margin-top: -3px;
        //     animation: shadow-pulse 1s infinite;
        // }
    }
    .block-signin {
        .btn-shadow {
            box-shadow: var(--jobbox-box-shadow-1);
        }
    }
    &.stick {
        padding: 0;

        .main-menu{
            li{
                padding: 28px 22px !important;
                &.has-children{
                    padding: 0;
                }

                &:hover{
                    ul{
                        margin-top: -18px;
                    }
                }

                ul{
                    li{
                        padding: 0!important;
                    }
                }
            }
        }
    }
    &.header-2 {
        background-color: var(--jobbox-background-5);
    }
}

.sticky-bar {
    &.stick {
        -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        left: 0;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        border-bottom: 1px solid var(--jobbox-border-color);
        background: #fff;
        &.sticky-white-bg {
            background-color: #fff;
        }
        &.sticky-blue-bg {
            background-color: #3286e0;
        }
    }
}
.header-brand-2
{
    background: var(--jobbox-color-brand-2);
    &.sticky-bar {
        &.stick {
            background: var(--jobbox-color-brand-2);
        }
    }
    .main-menu > li > a {
        color: var(--jobbox-border-color);
        &:hover {
            color: $color-white;
        }
        &:hover::before, .active::before {
            background-color: $color-white;
        }
    }
    .main-menu li a, .main-menu li a.active
    {
        color: $color-white;
        &:hover::before, .active::before {
            background-color: $color-white;
        }
    }

    .main-menu {
        li {
            a {
                color: var(--jobbox-border-color);
                &:hover, &.active {
                    color: $color-white;
                    &::before
                    {
                        background-color: $color-white;
                    }
                }
            }
            ul {
                li {
                    a {
                        &:hover {
                            color: var(--jobbox-color-brand-2);
                        }
                    }
                }
            }
        }
    }

    .text-link-bd-btom {
        color: $color-white;
    }
    .btn-default {
        background-color: $background-white;
        color: var(--jobbox-color-brand-2);
        font-weight: 500;
    }
}
.user-account {
    display: flex;
    align-items: center;
    img {
        max-width: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .user-name {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
    }
}
.perfect-scroll {
    height: 100vh;
    position: relative;
}
.body-overlay-1 {
	background: transparent;
	height: 100%;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 0;
	visibility: hidden;
	width: 100%;
	cursor: crosshair;
	z-index: 997;
}
.mobile-menu-active .body-overlay-1 {
	opacity: 1;
	visibility: visible;
}

.stick ~ .main {
    padding-top: var(--jobbox-sticky-main-padding-top, 108px);
}

@include media-breakpoint-down(xl) {
    body{
        --jobbox-sticky-main-padding-top: 98px;
    }
    
}

.admin-bar {
    #offcanvasMenu,
    .mobile-header-wrapper-style,
    .sticky-bar.stick{
        margin-top: 32px;
    }

}
@media only screen and (max-width: 782px) {
	.admin-bar {
        #offcanvasMenu,
        .mobile-header-wrapper-style,
        .sticky-bar.stick{
            margin-top: 46px;
        }
	}
}
@media only screen and (max-width: 600px) {
	.admin-bar {
        #offcanvasMenu{
            margin-top: 46px;
        }		
        .mobile-header-wrapper-style,
        .sticky-bar.stick{
            margin-top: 0;
            + #offcanvasMenu{
                margin-top: 0;
            }
        }
	}

    .header .main-header .header-left .header-logo {
        img{
            --jobbox-logo-size: var(--jobbox-logo-size-sm, auto);
        }
    }
}
