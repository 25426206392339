.jobbox-customizer{
    .ctrlbp-clone{
        padding-right: 0;
    }
    .ctrlbp-button{
        &.remove-clone{
            display: none !important;
        }
    } 
    .ctrlbp-group-remove{
        display: none !important;
    }
    .add-clone{
        display: none !important;
    }
}

.ctrljm-data-table{
    td,
    th{
        vertical-align: middle;
    }
}

.ctrljm-frontend-form{
    .ctrlbp-text-wrapper{
        &:has(input#post_title){
            display: none;
        }
    }
}

#job-manager-job-dashboard{
    table{
        td,
        th{
            padding: 10px;
            vertical-align: middle;
        }
    }
}